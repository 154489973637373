import InfiniteScroll from "infinite-scroll"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["notifications_list", "search_filter", "notification_toggle_button", "notification_item"];

  connect() {
    if (this.hasNotifications_listTarget) {
      const nextLink = this.notifications_listTarget.querySelector('.notifications_pagination__next');

      if (nextLink) {
        new InfiniteScroll(this.notifications_listTarget, {
          path: '.notifications_pagination__next',
          append: '.notification-item',
          history: false,
          prefill: true
        });
      }
    }
  }

  search(event) {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set('q', this.search_filterTarget.value);

    url.search = params.toString();
    this.historyPush(url.toString());
    this.updateNextLink(url.toString());
    this.loadData(url.toString());
  }

  filterByUnread(event) {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if (event.currentTarget.dataset.unread === 'true') {
      params.set('unread', true);
    } else {
      params.delete('unread');
    }

    url.search = params.toString();
    this.historyPush(url.toString());
    this.loadData(url.toString());
    this.updateNextLink(url.toString());
    this.toggleTabs(params.get('unread'));
  }

  updateNextLink(url) {
    const nextLink = document.querySelector('.notifications_pagination__next');
    if (nextLink) {
      const nextLinkUrl = new URL(nextLink.href);
      const nextLinkParams = new URLSearchParams(nextLinkUrl.search);
      const urlParams = new URLSearchParams(url);
      urlParams.forEach((value, key) => {
        if (!nextLinkParams.has(key)) {
          nextLinkParams.append(key, value);
        }
      });

      nextLinkUrl.search = nextLinkParams.toString();
      nextLink.href = nextLinkUrl.toString();
    }
  }

  toggleTabs(unread) {
    const unreadTab = document.getElementById('notify-notifications-tab-unread');
    const allTab = document.getElementById('notify-notifications-tab-all');

    if (unread === 'true') {
      unreadTab.classList.add('active');
      allTab.classList.remove('active');
    } else {
      unreadTab.classList.remove('active');
      allTab.classList.add('active');
    }
  }

  loadData(url) {
    fetch(url, { headers: { accept: "text/plain" } })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Failed to fetch notifications");
        }
      })
      .then((html) => {
        const notificationsList = document.getElementById("notifications_list");
        if (notificationsList) {
          notificationsList.innerHTML = html; // Update the DOM with the new notifications
        }
      })
      .catch((error) => console.error(error));
  }

  toggleRead(event) {
    const notificationId = event.currentTarget.dataset.notificationId; // Get the notification ID
    const url = `/notify/notifications/${notificationId}/toggle_read`;

    // Perform the POST request
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to toggle read status");
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        // Get the clicked notification item and icon
        const notificationItem = document.getElementById(`notification-item-${notificationId}`);
        const notificationIcon = document.getElementById(`notification-icon-${notificationId}`);
        const unreadNotificationCount = document.getElementById('notify-unread-notification-count-badge');
        const unreadCount = data.unread_count;

        window.vNotify.success({ text: data.notification_message, title: data.notification_title });

        // Toggle the unread class
        notificationItem.classList.toggle('notify-notifications__index-notification-unread');

        // Update the unread count
        if (unreadCount === 0) {
          unreadNotificationCount.classList.add('d-none');
        } else {
          unreadNotificationCount.classList.remove('d-none');
          unreadNotificationCount.innerHTML = unreadCount;
        }

        // Update the icon
        if (notificationIcon) {
          if (notificationIcon.classList.contains('fa-eye-slash')) {
            notificationIcon.classList.replace('fa-eye-slash', 'fa-eye');
          } else {
            notificationIcon.classList.replace('fa-eye', 'fa-eye-slash');
          }
        }
      } else {
        console.error('Failed to toggle read status');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}